<script>
// eslint-disable-next-line no-unused-vars
import TablePage from '../../../../../components/table_page';
// eslint-disable-next-line no-unused-vars
import request from '../../../../../utils/request';
import FormDetail from './form.vue';

export default {
  name: 'table-component',
  extends: TablePage,
  components: { FormDetail },
  data() {
    return {
      requestUrl: '/sfa/sfaSignFormsController/exceptionReportDetail',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  methods: {
    /** @desc 折叠搜索条件 */
    foldSearchList(n) {
      n.map((v, k) => {
        const rowData = v;
        if (k > 3) {
          rowData.folding = true;
        }
        return rowData;
      });
      return n;
    },
    /** @override 所有按钮打开弹窗事件 */
    modalClick({ val, row }) {
      if (val.code === 'view') return this.btnCheck(row);
    },
    /** @desc 查看详情的功能 */
    btnCheck(row) {
      this.formName = 'FormDetail';
      this.modalConfig.title = '查看详情';
      this.formConfig.row = row;
      this.openDrawer();
    },
    cellClick({ row, column }) {
      if (column.property === 'realName') {
        this.formName = 'FormDetail';
        this.formConfig = {
          type: 'view',
          row,
        };
        this.modalConfig.title = '查看详情';
        this.openDrawer();
      }
    },
  },
  created() {
    this.getConfigList('sfa_attendance_abnormal_report_details').then(() => {
      this.foldSearchList(this.searchList);
    });
  },
};
</script>
